/* eslint-disable no-process-env, prefer-object-spread */
import config, { num, bool } from 'config/config';

// we're using js so we can pull it more easily into webpack
// Update Config.tsx with types to match this
const exported = Object.assign({}, config, {
  QR_CODE_HOSTNAME: {
    value: process.env.REACT_APP_QR_CODE_HOSTNAME,
  },
  STUDIO_LESSON_POLL_INTERVAL_SECONDS: {
    value: num(process.env.REACT_APP_STUDIO_LESSON_POLL_INTERVAL_SECONDS),
    default: 10,
  },
  PRE_LOBBY_COUNTDOWN_SECONDS: {
    value: num(process.env.REACT_APP_PRE_LOBBY_COUNTDOWN_SECONDS),
    default: 600,
  },
  FAVOURITES_POLLING_INTERVAL: {
    value: num(process.env.REACT_APP_FAVOURITES_POLLING_INTERVAL),
    default: 5,
  },
  ALLOW_START_NOW: {
    value: bool(process.env.REACT_APP_ALLOW_START_NOW),
    format: Boolean,
    default: false,
  },
});

export default exported;
