import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useAppState } from 'state';

import useRoutes, { url } from 'utils/use-routes';
import { usePageScroll } from 'utils/use-page-scroll';

import { rem } from 'ui/helpers';

import StudioHeroCard from 'ui/components/molecules/studio-hero-card';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import Rail from 'ui/components/molecules/rail';
import BrowseCarousel, { HeroCardContent } from 'ui/components/molecules/scrolling-browse/carousel';
import {
  lessonsToRailNodes,
  contentPackageToRailNodes,
  contentPackageBlockToRailContent,
  ContentPackageBlockRailContent,
} from 'app/in-studio/pages/landing/helpers';
import lessonsQueryVariables from 'app/in-studio/utils/lessons-query-variables';

import useAuxiliaryWindow from 'app/hooks/use-auxiliary-window';

import {
  Landing as LANDING,
} from 'app/in-studio/pages/landing/landing.gql';
import Typography from 'ui/components/atoms/typography';

type LandingPageProps = {
  userId: number,
  virtualStudioId: string,
};

const Wrapper = styled.div`
  margin-left: ${rem(64)};
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const StyledRail = styled(Rail)`
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const StyledBrowseCarousel = styled(BrowseCarousel)`
  margin-top: 0;
`;

const LandingPage = ({ userId, virtualStudioId }: LandingPageProps) => {
  const { routes } = useRoutes();
  const pageScroll = usePageScroll();
  const permissions = useAppState((state) => state.virtualStudio.permissions);
  const { sendWebkitMessage } = useAuxiliaryWindow();

  useEffect(() => {
    sendWebkitMessage({ blankScreen: true });
  });

  const { data, loading, error } = useQuery(
    LANDING, {
      variables: {
        ...lessonsQueryVariables,
        lessonConditions: {
          ...lessonsQueryVariables.lessonConditions,
        },
        trendingRailLength: 20,
        newReleasesRailLength: 20,
        featuredRailLength: 20,
        contentPackageBlockRailsLength: 5,
        featuredRailSlug: 'featured-picks',
        userId,
        virtualStudioId,
      },
    },
  );

  const onFocus = useCallback((element: HTMLElement | null) => {
    if (element) {
      // element.offsetTop - 20 to account for top margin
      pageScroll(element.offsetTop - 20);
    }
  }, [pageScroll]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const showContentPackages = data.auth.features.contentPackages.value;

  const trendingRail = data.trendingRail?.edges?.map(lessonsToRailNodes(routes.LESSON_DETAILS)) || [];
  const newReleasesRail = data.newReleasesRail?.edges?.map(lessonsToRailNodes(routes.LESSON_DETAILS)) || [];
  const featuredRail = data.featuredRail?.lessons?.edges?.map(lessonsToRailNodes(routes.LESSON_DETAILS)) || [];
  const featuredRailName = data.featuredRail?.name;

  const fiitContentPackagesRail = data.fiitContentPackages.edges
    ?.map(contentPackageToRailNodes(routes.CONTENT_PACKAGE)) || [];
  const partnerContentPackagesRail = data.partnerContentPackages.edges
    ?.map(contentPackageToRailNodes(routes.CONTENT_PACKAGE)) || [];

  const purchasedContentPackageBlockRails: ContentPackageBlockRailContent[] =
    data.ownedContentPackageBlocks?.contentPackageBlocks.edges
      ?.map(contentPackageBlockToRailContent(routes.LESSON_DETAILS)) || [];

  const heroCardContent: HeroCardContent = [
    {
      title: 'UNLOCK FIIT CARDIO',
      description: (
        <Typography>
          Contact your PSM to find out more
        </Typography>
      ),
      backgroundImage: {
        url: 'https://images-bucket.prod.fiit-static.net/96f25a09-0eec-455a-9f71-1a4f0cd672ef.jpeg',
      },
    },
    {
      title: 'UNLOCK FIIT STRENGTH',
      description: (
        <Typography>
          Contact your PSM to find out more
        </Typography>
      ),
      backgroundImage: {
        url:
          'https://images-bucket.prod.fiit-static.net/4c94d4cd-182c-4168-8b48-e93e5b391994.jpeg',
      },
    },
    {
      title: 'UNLOCK FIIT REBALANCE',
      description: (
        <Typography>
          Contact your PSM to find out more
        </Typography>
      ),
      backgroundImage: {
        url: 'https://images-bucket.prod.fiit-static.net/6d850d67-e4ea-4947-a23d-2ebd675e2c45.jpeg',
      },
    },
    {
      title: 'UNLOCK FIIT SPIN',
      description: (
        <Typography>
          Contact your PSM to find out more
        </Typography>
      ),
      backgroundImage: {
        url: 'https://images-bucket.prod.fiit-static.net/c4d54751-5a4a-404c-aa0f-9afc8ccdfb20.jpeg',
      },
    },
  ];

  return (
    <Wrapper>
      {showContentPackages && (
        <StyledBrowseCarousel
          onFocus={() => null}
          heroCardContent={heroCardContent}
          autofocus={false}
          peek
          disableFocus
        />
      )}
      <HeroWrapper>
        <StudioHeroCard
          autofocus
          backgroundImage="https://images-bucket.prod.fiit-static.net/app/uploads/2023/09/22131623/Library.png"
          title="Library"
          description="Browse and Schedule Classes"
          {...(permissions.exerciseLibrary
            ? { to: url({ route: showContentPackages ? routes.LIBRARY : routes.FILTER }) }
            : {}
          )}
          locked={!permissions.exerciseLibrary}
        />
        <StudioHeroCard
          backgroundImage="https://images-bucket.prod.fiit-static.net/app/uploads/2023/08/29125208/Studio.png"
          title="Studio"
          description="Launch Full-Screen Class Experience"
          {...(permissions.liveStudioClass ? { to: url({ route: routes.UPCOMING }) } : {})}
          locked={!permissions.liveStudioClass}
        />
        <StudioHeroCard
          backgroundImage="https://images-bucket.prod.fiit-static.net/app/uploads/2023/08/29125213/Timetable.png"
          title="Timetable"
          description="Manage Upcoming Classes"
          {...(permissions.scheduler ? { to: url({ route: routes.SCHEDULE }) } : {})}
          locked={!permissions.scheduler}
        />
        <StudioHeroCard
          backgroundImage="https://images-bucket.prod.fiit-static.net/app/uploads/2023/08/29125203/Class-builder.png"
          title="Class Builder"
          description="Coming Soon..."
          locked={!permissions.classBuilder}
          comingSoon
        />

      </HeroWrapper>
      {newReleasesRail && newReleasesRail.length > 0 && permissions.exerciseLibrary && !showContentPackages && (
        <StyledRail
          onFocus={onFocus}
          title="New releases"
          key="newest"
        >
          { newReleasesRail}
        </StyledRail>
      )}
      {(trendingRail && trendingRail.length > 0) && permissions.exerciseLibrary && !showContentPackages && (
        <StyledRail
          title="Most popular"
          key="trending"
          onFocus={onFocus}
        >
          { trendingRail}
        </StyledRail>
      )}
      {(featuredRail && featuredRail.length > 0) && permissions.exerciseLibrary && !showContentPackages && (
        <StyledRail
          title={featuredRailName}
          key="featured"
          onFocus={onFocus}
        >
          { featuredRail}
        </StyledRail>
      )}
      {purchasedContentPackageBlockRails && purchasedContentPackageBlockRails.length > 0 && showContentPackages && (
        purchasedContentPackageBlockRails.map((purchasedContentPackageBlockRail) => (
          <StyledRail
            title={purchasedContentPackageBlockRail.name}
            subtitle={purchasedContentPackageBlockRail.subtitle}
            key={purchasedContentPackageBlockRail.name}
            onFocus={onFocus}
            noCapitalize
          >
            {purchasedContentPackageBlockRail.lessons}
          </StyledRail>
        ))
      )}
      {(fiitContentPackagesRail && fiitContentPackagesRail.length > 0) && showContentPackages && (
        <StyledRail
          title="FIIT workouts"
          key="fiitContentPackages"
          onFocus={onFocus}
          noCapitalize
        >
          { fiitContentPackagesRail }
        </StyledRail>
      )}
      {(partnerContentPackagesRail && partnerContentPackagesRail.length > 0) && showContentPackages && (
        <StyledRail
          title="Partner workouts"
          key="partnerContentPackages"
          onFocus={onFocus}
        >
          { partnerContentPackagesRail }
        </StyledRail>
      )}
    </Wrapper>
  );
};

const LandingPageWrapper = () => {
  const userId = useAppState((state) => state.auth.userId);
  const virtualStudioId = useAppState((state) => state.virtualStudio.id);

  if (!userId || !virtualStudioId) {
    return <ErrorOverlay onDismiss="back" />;
  }

  return <LandingPage userId={userId} virtualStudioId={virtualStudioId} />;
};

export default LandingPageWrapper;
