import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { useAppState } from 'state';
import useDismissEvent from 'app/hooks/use-dismiss-event';

import ClassDetails from 'ui/components/layouts/class-details';

import ScheduleModal from 'app/in-studio/pages/lesson/schedule-modals/schedule-modal';

import {
  LessonDetails as LESSON_QUERY,
} from 'app/in-studio/pages/lesson/graphql/lesson-details.gql';

import { StartWorkout as START_WORKOUT_MUTATION } from 'src/graphql/mutations/start-workout.gql';
import lessonsQueryVariables from 'app/in-studio/utils/lessons-query-variables';

import LoadingOverlay from 'ui/components/molecules/loading-screen';
import ErrorOverlay from 'ui/components/molecules/loading-error-screen';

import FullPageModal from 'ui/components/layouts/full-page-modal';

import ModalAlertContents from 'ui/components/molecules/modal-alert-contents';

import useLogger from 'app/hooks/use-logger';
import useAuxiliaryWindow from 'app/hooks/use-auxiliary-window';

import {
  LessonDetailsQuery,
  LessonDetailsQueryVariables,
  LessonDurationRange,
  LessonDifficulty,
  MuscleGroup,
  LessonStatus,
  TimelineCircuit,
  LessonStartPermission,
  StudioFamily,
  StartWorkoutMutation,
  StartWorkoutMutationVariables,
} from 'app/in-studio/types/graphql';
import transformLessonData, { TransformedLessons } from 'app/in-studio/utils/transform-lesson-data';
import { MappedCircuit, mapSections } from 'ui/components/molecules/current-exercise/utils';

import useRoutes from 'utils/use-routes';

import useConfig from 'app/in-studio/config-provider';

type ComponentProps = {
  id: string,
};

export type Props = RouteComponentProps<ComponentProps>;

type ClassDetailsScreenProps = {
  defaultMediaOption: {
    dashUrl?: string | null,
    id: string,
    commercial: boolean,
  },
  name: string,
  trainers: Array<{
    firstname: string,
    lastname: string,
    slug: string,
    avatarImage: {
      url: string,
    },
  }>,
  mainImage?: string,
  studio: {
    id: string,
    family: StudioFamily,
  },
  durationRange: LessonDurationRange,
  difficulty: LessonDifficulty,
  equipment?: string,
  musicGenre?: string,
  muscles: Array<{
    group: MuscleGroup,
  }>,
  description?: string | null,
  userId: number,
  permissions?: LessonStartPermission,
  favourited?: boolean,
  lessonId: number,
  inTesting?: boolean,
  similarClasses: TransformedLessons,
  review?: number | null,
  mappedTimeline: MappedCircuit[],
  supportsLive: boolean,
};

type StartNowModalProps = {
  onDismiss: () => void,
  onAccept: () => void,
};

const StartNowModal = ({ onDismiss, onAccept }: StartNowModalProps) => (
  <FullPageModal onDismiss={onDismiss}>
    <ModalAlertContents
      title="On Demand Class"
      onAcceptClick={onAccept}
      autofocus
    >
      This class starts instantly.
      Please note: there are no connectivity features like leaderboards or interaction with others.
    </ModalAlertContents>
  </FullPageModal>
);

const ClassDetailsScreen = ({
  mainImage,
  name,
  trainers,
  studio,
  durationRange,
  difficulty,
  equipment,
  musicGenre,
  muscles,
  description,
  userId,
  permissions,
  favourited = false,
  lessonId,
  inTesting,
  similarClasses,
  review,
  mappedTimeline,
  supportsLive,
  defaultMediaOption,
}: ClassDetailsScreenProps) => {
  const { sendWebkitMessage } = useAuxiliaryWindow();

  useEffect(() => {
    sendWebkitMessage({ blankScreen: true });
  });
  const logger = useLogger('in-studio-tv:lesson:details-screen');
  const dismissFocusId = useRef<string | null>(null);
  const { config } = useConfig();

  const setDismissFocusId = (id: string | null) => {
    dismissFocusId.current = id;
  };

  const history = useHistory();

  const { routes } = useRoutes();

  useDismissEvent();

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showStartNowModal, setShowStartNowModal] = useState(false);

  const scheduleOnClick = () => {
    setShowScheduleModal(true);
  };

  const onScheduleDismiss = () => {
    setShowScheduleModal(false);
  };

  const startOnClick = () => {
    setShowStartNowModal(true);
  };

  const onStartNowDismiss = () => {
    setShowStartNowModal(false);
  };

  const [startStreamableWorkout] = useMutation<StartWorkoutMutation, StartWorkoutMutationVariables>(
    START_WORKOUT_MUTATION,
    {
      variables: {
        userId,
        lessonMediaId: defaultMediaOption?.id,
      },
      onCompleted: ({ startStreamableWorkout: { workout } }) => {
        const lessonInstanceId = workout.lessonInstance.id;

        if (routes.LESSON_INSTANCE) {
          history.push(`/lesson-instance/${lessonInstanceId}`);
        }
      },
      onError: (e) => logger.error('StartWorkoutMutation error', { error: e }),
    },
  );

  return (
    <>
      {showScheduleModal && permissions?.value && (
        <ScheduleModal
          onDismiss={() => onScheduleDismiss()}
          lessonId={lessonId}
          userId={userId}
          supportsLive={supportsLive}
        />
      )}
      {showStartNowModal && permissions?.value && (
        <StartNowModal
          onDismiss={() => onStartNowDismiss()}
          onAccept={() => startStreamableWorkout()}
        />
      )}
      <ClassDetails
        image={mainImage}
        name={name}
        trainers={trainers}
        studio={studio?.id}
        duration={durationRange}
        difficulty={difficulty}
        equipment={equipment}
        musicGenre={musicGenre}
        muscles={muscles}
        description={description}
        permissions={permissions}
        autofocus
        favourited={favourited}
        lessonId={lessonId}
        userId={userId}
        inTesting={inTesting}
        similarClasses={similarClasses}
        setDismissFocusId={setDismissFocusId}
        review={review}
        mappedTimeline={mappedTimeline}
        scheduleOnClick={scheduleOnClick}
        noTitleTopPad
        {...(config.ALLOW_START_NOW ? { startOnClick } : null)}
      />
    </>
  );
};

type LessonDetailsProps = {
  id: number,
  userId: number,
};

const LessonDetails = ({ id, userId }: LessonDetailsProps) => {
  const logger = useLogger('in-studio-tv:lesson:details');

  const { loading, error, data } = useQuery<LessonDetailsQuery, LessonDetailsQueryVariables>(LESSON_QUERY, {
    variables: {
      ...lessonsQueryVariables,
      id,
      userId,
      relatedLessonsFirst: 6,
    },
    onError: (e) => logger.error('LessonDetailsQuery error', { error: e, lessonId: id }),
  });

  const similarClasses = useMemo(() => transformLessonData(data?.lessonById?.relatedLessons?.edges), [data]);
  const mappedTimeline = useMemo(() => {
    if (data?.lessonById?.timeline) {
      return mapSections(data.lessonById.timeline as TimelineCircuit[]);
    }
    return [];
  }, [data]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || !data?.lessonById) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const {
    mainImage,
    name,
    trainers,
    studio,
    durationRange,
    difficulty,
    equipmentLevel,
    musicGenre,
    muscles,
    description,
    defaultMediaOption,
    permissionsWithReasons,
    favouritedByUser: favourited,
    status,
    review,
    supportsLive,
  } = data.lessonById;

  const { auth: { permissions: { canScheduleLiveClasses } } } = data;

  return (
    <>
      { defaultMediaOption && (
      <ClassDetailsScreen
        defaultMediaOption={defaultMediaOption}
        mainImage={mainImage?.url}
        name={name}
        trainers={trainers}
        studio={studio}
        durationRange={durationRange}
        difficulty={difficulty}
        equipment={equipmentLevel[0]?.equipment?.shortDisplay}
        musicGenre={musicGenre?.displayName}
        muscles={muscles}
        description={description}
        userId={userId}
        permissions={permissionsWithReasons.start}
        favourited={favourited}
        lessonId={id}
        inTesting={status === LessonStatus.TESTING}
        similarClasses={similarClasses}
        review={review.overall.avg}
        mappedTimeline={mappedTimeline}
        supportsLive={supportsLive && canScheduleLiveClasses}
      />
      ) }
    </>
  );
};

const LessonPage = ({ match: { params } }: Props) => {
  const lessonId = parseInt(params.id, 10);
  const userId = useAppState((state) => state.auth.userId);

  if (!lessonId || !userId) {
    return <div>error</div>;
  }

  return <LessonDetails id={lessonId} userId={userId} />;
};

LessonPage.menu = true;

export default LessonPage;
