import React from 'react';
import {
  LessonDurationRange,
  LessonStatus,
} from 'app/in-studio/types/graphql';
import { RouteConfig } from 'app/on-tv/routes';
import { url } from 'utils/use-routes';

import { trainerNames, Trainers } from 'ui/components/utils/trainer-names';
import ClassCard from 'ui/components/molecules/class-card';
import ContentPackageCard from 'ui/components/molecules/content-package-card';
import { ContentPackageStatus } from 'types/graphql';

type LessonsToRailNodes = {
  node: {
    id: number,
    permissionsWithReasons?: {
      start: {
        value: boolean,
      }
    },
    mainImage?: {
      url: string,
    } | null,
    durationRange: LessonDurationRange,
    trainers: Trainers,
    name: string,
    workouts?: {
      totalCount: number,
    },
    equipmentLevel: Array<{ id: string, equipment: { value: string } }>,
    favouritedByUser?: boolean,
    status: LessonStatus,
  },
  scheduleId?: number,
};

type ContentPackageToRailNodes = {
  node: {
    id: number,
    name: string,
    cardImage?: {
      url: string,
    } | null,
    contentPackageBlocks: {
      totalCount: number,
    },
    status: ContentPackageStatus,
    partner: {
      id: string,
      logoImage: {
        id: number,
        url: string,
      } | null,
    } | null,
    permissionsWithReasons: {
      studioAccess: {
        value: boolean,
      },
    },
  },
};

type ContentPackageBlockToRail = {
  node: {
    id: string,
    name: string,
    description: string,
    contentPackage: {
      id: string,
      name: string,
    },
    lessons: {
      edges: LessonsToRailNodes[],
    },
  },
};

export type ContentPackageBlockRailContent = {
  name: string,
  subtitle: string,
  lessons: JSX.Element[],
};

export const lessonsToRailNodes = (route?: RouteConfig, showCompleted: boolean = true) => (
  ({ node, scheduleId }: LessonsToRailNodes, i: number) => (
    <ClassCard
      key={i}
      size="large"
      backgroundImage={node.mainImage?.url}
      duration={node.durationRange}
      trainer={trainerNames(node.trainers)}
      name={node.name}
      to={url({
        route,
        params: { id: node.id },
        ...(scheduleId ? { query: { scheduleId } } : {}),
      })}
      locked={node.permissionsWithReasons ? !node.permissionsWithReasons?.start.value : true}
      completed={(showCompleted && !!node.workouts?.totalCount) || false}
      kettlebells={!!node.equipmentLevel.find((equ) => equ.equipment.value === 'KETTLEBELL')}
      dumbbells={!!node.equipmentLevel.find((equ) => equ.equipment.value === 'DUMBBELLS')}
      favourited={node.favouritedByUser}
      inTesting={node.status === LessonStatus.TESTING}
    />
  )
);

/* eslint-disable-next-line max-len */
const noPartnerContentPackageLogo = 'https://images-bucket.prod.fiit-static.net/b67781dc-1284-41e5-83db-48468a385197.jpeg';

export const contentPackageToRailNodes = (route?: RouteConfig) => (
  ({ node }: ContentPackageToRailNodes, i: number) => (
    <ContentPackageCard
      key={i}
      backgroundImage={node.cardImage?.url}
      name={node.name}
      to={url({
        route,
        params: { id: node.id },
      })}
      inTesting={node.status === ContentPackageStatus.TESTING}
      blocks={node.contentPackageBlocks.totalCount}
      isPartner={!!node.partner}
      logoImageUrl={node.partner ? node.partner.logoImage?.url : noPartnerContentPackageLogo}
      locked={!node.permissionsWithReasons.studioAccess.value}
    />
  )
);

export const contentPackageBlockToRailContent = (lessonRoute?: RouteConfig) => (
  ({ node }: ContentPackageBlockToRail): ContentPackageBlockRailContent => {
    const lessonsRail = node.lessons.edges?.map(lessonsToRailNodes(lessonRoute)) || [];
    return {
      name: node.contentPackage.name,
      subtitle: node.name,
      lessons: lessonsRail,
    };
  }
);
